import React, { useState } from "react";
import { Modal, ModalFooter, Button, Form, Row, Col } from "react-bootstrap";
import { LoginCredentials } from "../network/users_api"
import { useForm } from "react-hook-form";
import * as UsersApi from "../network/users_api"
import { User } from "../models/user";


interface LoginDialogProps {
    onDismiss: () => void,
    onLoginSuccessful: (user: User) => void
}

const LoginModal = ({ onDismiss, onLoginSuccessful }: LoginDialogProps) => {

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<LoginCredentials>()

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
    };

    async function onSubmit() {
        try {
            const input: LoginCredentials = {
                email: email,
                password: password
            };
            const user = await UsersApi.login(input)
            onLoginSuccessful(user);
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }

    return (
        <Modal show onHide={onDismiss} centered backdrop="static">
            <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto">
                    Login
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="signUpForm" onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                isInvalid={!!errors.email}
                                {...register("email", { required: "Email address is required" })}
                                onChange={handleEmailChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                isInvalid={!!errors.password}
                                {...register("password", { required: "Password is required" })}
                                onChange={handlePasswordChange}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.password?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <ModalFooter>
                <Button variant="primary" type="submit" form="signUpForm" disabled={isSubmitting} className="w-100">
                    Login
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default LoginModal;