import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { CheckIn } from "../models/checkin";
import { useForm } from "react-hook-form";
import { Employee as EmployeeModel } from '../models/employee';
import { CheckinInput } from "../network/checkins_api";
import * as CheckInApi from "../network/checkins_api"
import * as EmployeesApi from "../network/employees_api"
import { useEffect, useState } from "react";

interface AddEditCheckInDialogProps {
	checkInToEdit?: CheckIn,
	onDismiss: () => void,
	onCheckInSaved: (checkIn: CheckIn) => void
}

const AddEditCheckInDialog = ({ checkInToEdit, onDismiss, onCheckInSaved }: AddEditCheckInDialogProps) => {

	const [employeeList, setEmployeeList] = useState<EmployeeModel[]>([]);

	const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<CheckinInput>(
		{
			defaultValues: {
				userName: checkInToEdit?.userName || "",
				serviceProvider: checkInToEdit?.serviceProvider || "Choose...",
			}
		}
	);

	const [userName, setUserName] = useState(checkInToEdit?.userName || "");
	const [serviceProvider, setServiceProvider] = useState(checkInToEdit?.serviceProvider || 'Anyone Available');
	const [serviceTypeList, setServiceTypeList] = useState<string[]>([]);
	const [serviceTypesAPIList, setServiceTypesAPIList] = useState<string[]>([]);


	const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newUserName = event.target.value;
		setUserName(newUserName);
	};

	const handleServiceTypeChange = (e: React.ChangeEvent<HTMLInputElement>, selectedService: string): void => {
		const isChecked: boolean = e.target.checked;
		setServiceTypeList((prevServiceType) => {
			if (isChecked) {
				// If the checkbox is checked, add the selected service to the state
				return [...prevServiceType, selectedService];
			} else {
				// If the checkbox is unchecked, remove the selected service from the state
				return prevServiceType.filter((service) => service !== selectedService);
			}
		});
	};


	async function onSubmit() {
		try {
			let checkInResponse: CheckIn;

			const input: CheckinInput = {
				userName: userName,
				serviceProvider: serviceProvider,
				serviceType: serviceTypeList.join(', '),
			};

			if (checkInToEdit) {
				checkInResponse = await CheckInApi.updateCheckIn(checkInToEdit._id, input)
			} else {
				checkInResponse = await CheckInApi.createCheckIn(input)
			}

			onCheckInSaved(checkInResponse);
		} catch (error) {
			console.log(error);
			alert(error);
		}
	}

	useEffect(() => {
		async function loadServiceTypes() {
			try {
				//const serviceTypes: string[] = await ServiceTypesApi.fetchServiceTypes();
				const serviceTypes = ["Haircut (Men)", "Haircut (Women)", "Beard cut", "Hair styling", "Layered haircut", "Coloring", "Highlighting", "Root touch-up", "Bang trim", "Eyebrow", "Upper lip", "Forehead", "Full face", "Eyebrow tinting", "Deep cleaning", "Herbal facials", "Gold facials", "Hydro facials", "Shampoo", "Under-arm wax", "Full-arm wax", "Half-arm wax", "Full-leg wax", "Half-leg wax", "Brazilian wax", "Full-body wax", "Hair Perm", "Perm. curling", "Perm. straight", "Keratin treatment", "Keratin straightening"];
				setServiceTypesAPIList(serviceTypes);
			} catch (error) {
				console.error(error);
				// Handle service types API call errors
			}
		}
		loadServiceTypes();
	}, []);

	useEffect(() => {
		async function loadEmployees() {
			try {
				const employees: EmployeeModel[] = await EmployeesApi.fetchEmployees();
				setEmployeeList(employees);
			} catch (error) {
				console.error(error);
			}
		}
		loadEmployees();
	}, []);

	return (
		<Modal show onHide={onDismiss} centered backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>
					{checkInToEdit ? "Edit CheckIn" : "New CheckIn"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form id="addEditCheckInForm" onSubmit={handleSubmit(onSubmit)}>
					<Row className="mb-3">
						<Form.Group as={Col} controlId="formGridFirstName">
							<Form.Label>Full Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Full name"
								isInvalid={!!errors.userName}
								{...register("userName", { required: "User name is required" })}
								onChange={handleUserNameChange}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.userName?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Row>

					{/* <Form.Group as={Col} className="mb-3" id="formGridCheckbox">
						<Form.Label>Service Needed</Form.Label>
						<div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
							<Form.Check type="checkbox" label="Hair cut" {...register("serviceType", { validate: (value) => serviceType.length > 0 || "At least one service type must be selected" })} value="Hair cut" id="hairCutCheckbox" onChange={(e) => handleServiceTypeChange(e, "Hair cut")} />
							<Form.Check type="checkbox" label="Hair color" {...register("serviceType", { validate: (value) => serviceType.length > 0 || "At least one service type must be selected" })} value="Hair color" id="hairColorCheckbox" onChange={(e) => handleServiceTypeChange(e, "Hair color")} />
							<Form.Check type="checkbox" label="Eyebrow" {...register("serviceType", { validate: (value) => serviceType.length > 0 || "At least one service type must be selected" })} value="Eyebrow" id="eyebrowCheckbox" onChange={(e) => handleServiceTypeChange(e, "Eyebrow")} />
							<Form.Check type="checkbox" label="Straightning" {...register("serviceType", { validate: (value) => serviceType.length > 0 || "At least one service type must be selected" })} value="Straightning" id="straightningCheckbox" onChange={(e) => handleServiceTypeChange(e, "Straightning")} />
							<Form.Check type="checkbox" label="Facial" {...register("serviceType", { validate: (value) => serviceType.length > 0 || "At least one service type must be selected" })} value="Facial" id="facialCheckbox" onChange={(e) => handleServiceTypeChange(e, "Facial")} />
							<Form.Check type="checkbox" label="Body waxing" {...register("serviceType", { validate: (value) => serviceType.length > 0 || "At least one service type must be selected" })} value="Body waxing" id="bodyWaxingCheckbox" onChange={(e) => handleServiceTypeChange(e, "Body waxing")} />
							<Form.Check type="checkbox" label="Highlights" {...register("serviceType", { validate: (value) => serviceType.length > 0 || "At least one service type must be selected" })} value="Highlights" id="highlightsCheckbox" onChange={(e) => handleServiceTypeChange(e, "Highlights")} />
							<Form.Check type="checkbox" label="Other" {...register("serviceType", { validate: (value) => serviceType.length > 0 || "At least one service type must be selected" })} value="Other" id="otherCheckbox" onChange={(e) => handleServiceTypeChange(e, "Other")} />
						</div>
						<div className="invalid-feedback d-block">
							{errors.serviceType?.message}
						</div>
					</Form.Group> */}

					<Form.Group as={Col} className="mb-3" id="formGridCheckbox">
						<Form.Label>Service Needed</Form.Label>
						<div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
							{serviceTypesAPIList.map((serviceType) => (
								<Form.Check
									key={serviceType}
									type="checkbox"
									label={serviceType}
									{...register("serviceType", { validate: (value) => serviceTypeList.length > 0 || "At least one service type must be selected" })} 
									value={serviceType}
									id={`${serviceType}Checkbox`}
									onChange={(e) => handleServiceTypeChange(e, serviceType)}
									//checked={serviceTypesAPIList.includes(serviceType)}
								/>
							))}
							<Form.Check type="checkbox" label="Other" {...register("serviceType", { validate: (value) => serviceTypeList.length > 0 || "At least one service type must be selected" })} value="Other" id="otherCheckbox" onChange={(e) => handleServiceTypeChange(e, "Other")} />
						</div>
						<div className="invalid-feedback d-block">
							{errors.serviceType?.message}
						</div>
					</Form.Group>

					<Form.Group className="mb-3" controlId="formGridAddress1">
						<Form.Label>Service Provider</Form.Label>
						<Form.Select defaultValue="Choose..." onChange={(e) => setServiceProvider(e.target.value)}>
							<option>Choose...</option>
							<option>Anyone Available</option>
							{employeeList.map((employee) => {
								if (employee && employee.fullName) {
									const fullNameParts = employee.fullName.split(' ');
									const formattedFullName = fullNameParts.map((part) => {
										return part.charAt(0).toUpperCase() + part.slice(1);
									}).join(' ');

									return (
										<option key={employee._id}>{formattedFullName}</option>
									);
								}
								return null;
							})}

						</Form.Select>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" type="submit" form="addEditCheckInForm" disabled={isSubmitting}>
					Submit
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default AddEditCheckInDialog;