import { Container } from "react-bootstrap";

const PrivacyPage = () => {
	return (
		<Container>
			<>
				<h3>Privacy Policy</h3>
				<p>At Perfect Threadings, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information when you use our services. By using our services, you agree to the terms of this Privacy Policy.</p>

				<h6>1. Information We Collect</h6>
				<p>We collect limited personal information to provide you with our services. The only personal information we collect is your email address, which is used solely for the purpose of uniquely identifying you as a user of our platform. We do not collect any other personally identifiable information unless explicitly provided by you.</p>

				<h6>2. Use of Your Email Address</h6>
				<p>Your email address is used exclusively to create and manage your account. It is not shared with third parties, and we do not send promotional or marketing emails without your consent. We may occasionally send important service-related notifications or updates.</p>

				<h6>3. Password Security</h6>
				<p>We take the security of your password seriously. Passwords are securely stored using industry-standard hash algorithms. We do not have access to your password in plain text, and we recommend that you choose a strong and unique password for your account.</p>

				<h6>4. Data Retention</h6>
				<p>We retain your personal information for as long as necessary to provide you with our services or as required by applicable laws and regulations. If you choose to delete your account, your personal information will be permanently deleted from our systems.</p>

				<h6>5. Cookies and Tracking</h6>
				<p>We may use cookies and similar technologies to improve your user experience. These technologies do not collect personal information but may collect usage data to help us understand how our services are used.</p>

				<h6>6. Third-Party Services</h6>
				<p>Our services may include links to third-party websites or services. We are not responsible for the privacy practices of these third parties, and we encourage you to review their privacy policies before using their services.</p>

				<h6>7. Children's Privacy</h6>
				<p>Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take appropriate action.</p>

				<h6>8. Changes to this Privacy Policy</h6>
				<p>We may update this Privacy Policy from time to time to reflect changes in our practices or for legal and regulatory reasons. We will notify you of any significant changes through our platform or by other means.</p>

				<h6>9. Contact Us</h6>
				<p>If you have any questions, concerns, or requests related to your privacy or this Privacy Policy, please contact us at 12990 National Rd SW, Etna, OH 43062.</p>

				<p>This Privacy Policy was last updated on 09/20/2023.</p>
			</>
		</Container>
	)
}

export default PrivacyPage;