import { useEffect, useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import { CheckIn as CheckInModel } from '../models/checkin';
import { User } from "../models/user";
import * as CheckInsApi from "../network/checkins_api";
import AddEditCheckInDialog from "./AddEditCheckInDialog";
import CheckIn from "./CheckIn";
import ConfirmationDialog from "./ConfirmationDialog";

interface CheckInsPageLoggedInViewProps {
    loggedInUser: User | null,
}


const CheckinsPageLoggedInView = ({ loggedInUser }: CheckInsPageLoggedInViewProps) => {

    const [checkIns, setCheckIns] = useState<CheckInModel[]>([]);
    const [recentCheckIns, setRecentCheckIns] = useState<CheckInModel[]>([]);
    const [showAddCheckInDialog, setShowAddCheckInDialog] = useState(false);
    const [showDeleteConformationDialog, setShowDeleteConformationDialog] = useState(false);
    const [showCheckInConformationDialog, setShowCheckInConformationDialog] = useState(false);
    const [selectedCheckIn, setSelectedCheckIn] = useState<CheckInModel | null>(null);
    const [checkInToEdit, setcheckInToEdit] = useState<CheckInModel | null>(null);
    const [isCheckInsLoading, setIsCheckInsLoading] = useState(true);
    const [shouldShowCheckInsLoadingError, setShouldShowCheckInsLoadingError] = useState(false);

    useEffect(() => {
        async function loadCheckIns() {
            try {
                setShouldShowCheckInsLoadingError(false);
                setIsCheckInsLoading(true);
                const checkIns: CheckInModel[] = await CheckInsApi.fetchCheckins();
                const checkInsList: CheckInModel[] = checkIns.map((checkIn) => {
                    return {
                        _id: checkIn._id,
                        userName: `${checkIn?.userName || 'Unknown User'}`,
                        serviceProvider: `${checkIn?.serviceProvider || 'Unknown Employee'}`,
                        serviceType: checkIn.serviceType,
                        status: checkIn.status,
                        createdAt: checkIn.createdAt,
                        updatedAt: checkIn.updatedAt
                    };
                });

                setCheckIns(checkInsList.filter((checkIn) => checkIn.status === "Created"));
                setRecentCheckIns(checkInsList.filter((checkIn) => checkIn.status === "Checked In"))
            } catch (error) {
                console.error(error);
                setShouldShowCheckInsLoadingError(true);
            } finally {
                setIsCheckInsLoading(false);
            }
        }
        loadCheckIns();
    }, []);

    async function deleteCheckIn(checkIn: CheckInModel) {
        try {
            await CheckInsApi.deleteCheckIn(checkIn._id);
            if (checkIn.status === "Created") {
                setCheckIns(checkIns.filter(existingCheckIn => existingCheckIn._id !== checkIn._id));
            } else {
                setRecentCheckIns(recentCheckIns.filter(existingCheckIn => existingCheckIn._id !== checkIn._id));
            }
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }

    async function doCheckIn(checkIn: CheckInModel) {
        try {
            const updatedCheckIn: CheckInModel = {
                ...checkIn,
                status: "Checked In",
            }
            await CheckInsApi.updateCheckIn(checkIn._id, updatedCheckIn);
            setCheckIns(checkIns.filter(existingCheckIn => existingCheckIn._id !== checkIn._id));
            setRecentCheckIns([...recentCheckIns, updatedCheckIn])
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }

    async function handleDeleteCheckInClick(checkIn: CheckInModel) {
        setSelectedCheckIn(checkIn);
        setShowDeleteConformationDialog(true);
    }

    async function handleCheckInClick(checkIn: CheckInModel) {
        setSelectedCheckIn(checkIn);
        setShowCheckInConformationDialog(true);
    }

    const checkInsTable = checkIns.map((checkIn, index) => (
        <div className="p-1" key={checkIn._id}>
            <CheckIn
                checkInIndex={index + 1}
                onCheckInRowClicked={setcheckInToEdit}
                checkInDisabled={false}
                deleteDisabled={false}
                rowClickable={true}
                checkIn={checkIn}
                onCheckInButtonClicked={handleCheckInClick}
                onDeleteButtonClicked={handleDeleteCheckInClick}
            />
        </div>
    ));


    const recentCheckInsTable = (
        <>
            <hr />
            <h4>Completed Check-ins</h4>
            {recentCheckIns.slice().reverse().map((checkIn, index) => (
                <div className="p-1" key={checkIn._id}>
                    <CheckIn
                        checkInIndex={index + 1}
                        onCheckInRowClicked={setcheckInToEdit}
                        checkInDisabled={true}
                        deleteDisabled={true}
                        rowClickable={false}
                        checkIn={checkIn}
                        onCheckInButtonClicked={handleCheckInClick}
                        onDeleteButtonClicked={handleDeleteCheckInClick}
                    />
                </div>
            ))}
        </>
    );

    return (
        <>
            <Container>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={() => setShowAddCheckInDialog(true)} variant="primary" className='mb-3'>
                        New Check In
                    </Button>
                </div>

                {isCheckInsLoading && <Spinner animation='border' variant='primary' className='centerElement' />}
                {shouldShowCheckInsLoadingError && <h6 className='centerElement'>
                    Something went wrong. Please refresh the page.
                </h6>}
                {!isCheckInsLoading && !shouldShowCheckInsLoadingError &&
                    <>
                        {checkIns.length > 0
                            ? checkInsTable
                            : <h6 className='centerElement'>
                                There are no more check-ins to display. Please refresh the page in a while.
                            </h6>
                        }
                    </>
                }

                {!isCheckInsLoading && recentCheckIns.length > 0 && (
                    <>
                        {recentCheckInsTable}
                    </>
                )}


                {showAddCheckInDialog &&
                    <AddEditCheckInDialog
                        onDismiss={() => setShowAddCheckInDialog(false)}
                        onCheckInSaved={(newCheckin) => {
                            setShowAddCheckInDialog(false);
                            setCheckIns([...checkIns, newCheckin])
                        }}
                    />
                }

                {checkInToEdit &&
                    <AddEditCheckInDialog
                        checkInToEdit={checkInToEdit}
                        onDismiss={() => setcheckInToEdit(null)}
                        onCheckInSaved={(updateCheckIn) => {
                            setcheckInToEdit(null)
                            setCheckIns(checkIns.map(existingCheckIn => existingCheckIn._id === updateCheckIn._id ? updateCheckIn : existingCheckIn))
                        }}
                    />
                }

                {showDeleteConformationDialog && selectedCheckIn && (
                    <ConfirmationDialog
                        onDismiss={() => {
                            setSelectedCheckIn(null); // Clear selectedCheckIn when the dialog is dismissed
                            setShowDeleteConformationDialog(false);
                        }}
                        title="Are you sure want to delete this checkin?"
                        message="Once you hit Delete, this checkin will be deleted and this action cannot be undone."
                        positiveButtonText="Cancel"
                        negativeButtonText="Delete"
                        onPositiveButtonClick={() => {
                            setSelectedCheckIn(null); // Clear selectedCheckIn when the dialog is dismissed
                            setShowDeleteConformationDialog(false);
                        }}
                        onNegativeButtonClick={() => {
                            setSelectedCheckIn(null); // Clear selectedCheckIn when the dialog is dismissed
                            setShowDeleteConformationDialog(false);
                            deleteCheckIn(selectedCheckIn)
                        }}
                    />
                )}

                {showCheckInConformationDialog && selectedCheckIn && (
                    <ConfirmationDialog
                        onDismiss={() => {
                            setSelectedCheckIn(null); // Clear selectedCheckIn when the dialog is dismissed
                            setShowCheckInConformationDialog(false);
                        }}
                        title="Customer Check In"
                        message="Are you sure want to Check this customer in?"
                        positiveButtonText="Check In"
                        negativeButtonText="Cancel"
                        onPositiveButtonClick={() => {
                            setSelectedCheckIn(null); // Clear selectedCheckIn when the dialog is dismissed
                            setShowCheckInConformationDialog(false);
                            doCheckIn(selectedCheckIn)
                        }}
                        onNegativeButtonClick={() => {
                            setSelectedCheckIn(null); // Clear selectedCheckIn when the dialog is dismissed
                            setShowCheckInConformationDialog(false);
                        }}
                    />
                )}
            </Container>
        </>
    );
}

export default CheckinsPageLoggedInView;