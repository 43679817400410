import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginModal from './components/LoginDialog';
import NavBar from './components/NavBar';
import SignupModal from './components/SignupDialog';
import { User } from './models/user';
import * as UsersApi from "./network/users_api";
import CheckInsPage from './pages/CheckInsPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivacyPage from './pages/PrivacyPage';

function App() {

	const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
	const [shouldShowSignUpDialog, setShouldShowSignUpDialog] = useState(false);
	const [shouldShowLogInDialog, setShouldShowLogInDialog] = useState(false);

	useEffect(() => {
		async function fetchLoggedInUser() {
			try {
				const user = await UsersApi.getLoggedInUser()
				setLoggedInUser(user)
			} catch (error) {
				console.error(error);
			}
		}

		fetchLoggedInUser();
	}, []);

	const refreshTime = 5 * 60 * 1000; // 5 minutes in milliseconds
	let timeout: NodeJS.Timeout;

	const resetTimer = () => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			window.location.reload();
		}, refreshTime);
	};

	useEffect(() => {
		// Set up event listeners for user interactions
		window.addEventListener('mousemove', resetTimer);
		window.addEventListener('mousedown', resetTimer);
		window.addEventListener('keydown', resetTimer);
		window.addEventListener('scroll', resetTimer);
		window.addEventListener('touchstart', resetTimer); 

		// Start the initial timer
		resetTimer();

		// Clean up event listeners when the component unmounts
		return () => {
			window.removeEventListener('mousemove', resetTimer);
			window.removeEventListener('mousedown', resetTimer);
			window.removeEventListener('keydown', resetTimer);
			window.removeEventListener('scroll', resetTimer);
			window.removeEventListener('touchstart', resetTimer);
		};
	}, []);


	return (
		<BrowserRouter>
			<div>
				<NavBar
					loggedInUser={loggedInUser}
					onLogInClicked={() => { setShouldShowLogInDialog(true) }}
					onSignUpClicked={() => { setShouldShowSignUpDialog(true) }}
					onLogoutSuccessful={() => { setLoggedInUser(null) }}
				/>

				<Container style={{ padding: '20px' }}>
					<Routes>
						<Route
							path='/'
							element={(
								<CheckInsPage
									loggedInUser={loggedInUser}
									onLoginClicked={() => setShouldShowLogInDialog(true)}
									onSignUpClicked={() => setShouldShowSignUpDialog(true)}
								/>
							)}
						/>
						<Route
							path='/privacy'
							element={<PrivacyPage />}
						/>
						<Route
							path='/*'
							element={<NotFoundPage />}
						/>
					</Routes>
				</Container>

				{shouldShowSignUpDialog &&
					<SignupModal
						onDismiss={() => { setShouldShowSignUpDialog(false) }}
						onSignUpSuccessful={(user) => {
							setLoggedInUser(user);
							setShouldShowSignUpDialog(false)
						}}
					/>
				}
				{shouldShowLogInDialog &&
					<LoginModal
						onDismiss={() => { setShouldShowLogInDialog(false) }}
						onLoginSuccessful={(user) => {
							setLoggedInUser(user);
							setShouldShowLogInDialog(false)
						}}
					/>
				}
			</div>
		</BrowserRouter>
	);
}

export default App;
