import { Container } from "react-bootstrap"
import { User } from '../models/user';
import CheckinsPageLoggedInView from "../components/CheckinsPageLoggedInView";
import UserDoCheckInPageLoggedInView from "../components/UserDoCheckInPageLoggedInView";
import CheckinsPageLoggedOutView from "../components/CheckinsPageLoggedOutView";

interface ChecckInsPageProps {
	loggedInUser: User | null,
	onLoginClicked: () => void,
	onSignUpClicked: () => void
}

const CheckInsPage = ({ loggedInUser, onLoginClicked, onSignUpClicked }: ChecckInsPageProps) => {
	return (
		<Container>
			<>
				{loggedInUser
					? (
						loggedInUser?.isAdmin
							? <CheckinsPageLoggedInView loggedInUser={loggedInUser} />
							: <UserDoCheckInPageLoggedInView />
					)
					: (
						<CheckinsPageLoggedOutView onLoginClicked={onLoginClicked} onSignUpClicked={onSignUpClicked} />
					)
				}
			</>
		</Container>
	)
}

export default CheckInsPage;