
export async function fetchData(input:RequestInfo, init?: RequestInit) {
    const response = await fetch(input, init)
    if(response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = "\nCode: " + errorBody.error.code + "\nMessage: " + errorBody.error.message;
        throw Error(errorMessage);
    }
}