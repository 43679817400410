import { Container } from "react-bootstrap";

const NotFoundPage = () => {
	return (
		<Container>
			<>
				<h1>Not Found</h1>
				<p>That page is not found.</p>
			</>
		</Container>
	)
}

export default NotFoundPage;